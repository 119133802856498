<template>
  <v-container>
    <v-btn text @click="toUsers">
      <v-icon left>mdi-arrow-left</v-icon>
      ユーザ一覧に戻る
    </v-btn>
    <v-row>
      <v-col  cols="12" class="text-center">
        <h1 class="display-1 font-weight-bold mb-3">ユーザ一括登録(CSV)</h1>
      </v-col>
      <v-col cols="8">
        <v-switch
          v-model="isUpdate"
          label="更新処理"
          hide-details
          class="mb-4"
          @change="openConfirmDialog"
        ></v-switch>
        <div class="text-h6 mb-4 font-weight-bold">ファイル選択</div>
        <v-row>
          <v-file-input
            v-model="file"
            label="ユーザ情報CSV"
            placeholder="ファイルを選択してください"
            outlined
            dense
            accept=".csv"
            :rules="rules"
            truncate-length="42"
          />
          <v-btn
            :color="color"
            class="ml-4"
            @click="importFile"
            :loading="uploading"
            :disabled="!(file)"
            width="160px"
          >
            インポート
            <template v-slot:loader>
              <span>インポート中...</span>
            </template>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="finish">
        <div class="text-h6 mb-4 font-weight-bold">結果</div>
        <div class="text-body-1 mb-2">
          失敗: {{ summary.failed }}, 更新: {{ summary.updated }}, 新規: {{ summary.inserted }}, 合計: {{ summary.total }}
        </div>
        <div v-if="errors.length" class="mb-3">
          <v-alert
            v-for="e in errors"
            :key="e.user"
            dense
            outlined
            type="error"
          >
            {{ e }}
          </v-alert>
        </div>
      </v-col>
      <v-col cols="8">
        <div class="text-h6 mb-4 font-weight-bold">パスワード情報</div>
        <v-data-table
          :headers="headers"
          :items="files"
          :loading="loading"
          hide-default-header
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon @click="fileDownload(item)">
              mdi-download
            </v-icon>
            <v-icon @click="openFileDeleteDialog(item)" class="ml-2">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
      <div class="ma-4">
        ※更新処理時はパスワードは生成されません。<br/>
        ※パスワードファイルをローカルに保存したらシステム上からは削除してください。
      </div>
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          インポート中
        </v-card-title>
        <v-card-text>
          しばらくお待ちください。
          <v-progress-circular
            indeterminate
            class="ml-2"
            :size="20"
            :width="2"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="500px"
      @click:outside="deleteDialog = false"
    >
      <v-card>
        <v-card-title>
          <span class="headline">ファイル削除</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="deleteDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          本当に "{{ deleteFile }}" を削除していいですか？<br>
          この操作は取り消しできません！
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteDialog=false">キャンセル</v-btn>
          <v-btn color="error" @click="fileDelete" :loading="deleteLoading">はい、削除します</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmDialog"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">確認</span>
        </v-card-title>
        <v-card-text>
          更新処理をONにすると、インポート時に既存のユーザの情報が更新されます。よろしいですか？
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmCancel">キャンセル</v-btn>
          <v-btn color="error" @click="confirmOK">はい</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "AdminNPA-2",
  data: () => ({
    file: undefined,
    uploading: false,
    finish: false,
    success: false,
    count: 0,
    summary: {
      failed: 0,
      updated: 0,
      inserted: 0,
      total: 0
    },
    errors: [],
    dialog: false,
    rules: [
      value => !value || value.size < 500000 || 'ファイルサイズのサイズ制限は500KBです。',
    ],

    // users csv fetch
    headers: [
      { text: 'file', value: 'file', width: '80%'},
      { text: '', value: 'actions', sortable: false, width: '20%' },
    ],
    files: [],
    loading: false,
    isUpdate: false,

    // delete
    deleteDialog: false,
    deleteFile: '',
    deleteLoading: false,

    // update confirm
    confirmDialog: false
  }),
  computed: {
    color: function() {
      return this.uploading ? 'success' : 'primary'
    }
  },
  created() {
    this.getUsersCsvFiles()
  },
  methods: {
    async postFile(form) {
      this.dialog = true
      this.finish = false
      this.errors = []
      const accessToken = await this.$auth.getTokenSilently();
      axios
        .post("/api/users-imports", form, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(response => {
          this.success = true;
          this.summary = response.data.summary
          this.errors = response.data.errors
          this.file = undefined
          this.getUsersCsvFiles()
        })
        .catch(error => {
          this.success = false;
          if (error.response.status === 400) {
            this.errors = error.response.data.errors
          } else if (error.response.status === 401) {
            this.errors.push(error.response.data.detail)
          } else if (error.response.status === 403) {
            this.errors.push(error.response.data.detail)
          } else if (error.response.status === 413) {
            this.errors.push("データサイズが上限値を超えています。")
          } else if (error.response.status === 415) {
            this.errors = error.response.data.errors
          } else if (error.response.status === 504) {
            this.errors.push("タイムアウトエラー。")
          } else {
            this.errors.push("エラーが発生しました。管理者にお問い合わせください。")
          }
        })
        .finally(() => {
          this.uploading = false
          this.finish = true
          this.dialog = false
        });
    },
    importFile() {
      if (this.file) {
        this.uploading = true;
        let form = new FormData();
        form.append("file", this.file);
        form.append("upsert", this.isUpdate);
        this.postFile(form)
      }
    },
    toUsers() {
      this.$router.push({name:'Users'})
    },
    async getUsersCsvFiles() {
      this.loading = true
      const accessToken = await this.$auth.getTokenSilently();
      axios
        .get("/api/users-imports/csv", {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(response => {
          this.files = response.data.files
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        });
    },
    async fileDownload(file) {
      const accessToken = await this.$auth.getTokenSilently();
      axios
        .get(`/api/users-imports/csv/${file.file}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          responseType: 'blob'
        })
        .then(response => {
          const url = URL.createObjectURL( new Blob([response.data], { type: "application/zip" }) );
          const link = document.createElement("a");
          link.href = url;
          link.download = file.file
          link.click();
          URL.revokeObjectURL(url);
        })
        .catch(error => {
          console.log(error)
        })
    },
    openFileDeleteDialog(item) {
      this.deleteFile = item.file
      this.deleteDialog = true
    },
    async fileDelete() {
      const accessToken = await this.$auth.getTokenSilently();
      axios
        .delete(`/api/users-imports/csv/${this.deleteFile}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          responseType: 'blob'
        })
        .then(() => {
          this.getUsersCsvFiles()
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.deleteDialog = false
        })
    },
    openConfirmDialog() {
      if (this.isUpdate) {
        this.confirmDialog = true
      }
    },
    confirmOK() {
      this.isUpdate = true
      this.confirmDialog = false
    },
    confirmCancel() {
      this.isUpdate = false
      this.confirmDialog = false
    },
  }
};
</script>